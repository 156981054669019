
import Vue from 'vue';
import Component from 'vue-class-component';
import { ICellRendererParams } from '@ag-grid-community/core';
import { getSportImage } from '@/lib/image';

@Component
export default class SportLeagueEventCell extends Vue {
  params: ICellRendererParams | undefined;

  get hasData() {
    return !!this.params?.data;
  }

  get sportCode() {
    if (!this.hasData) return '';
    return this.params?.data.sport_code;
  }

  get home() {
    if (!this.hasData) return '';
    if (this.params?.data.event_type === 'A') return this.params.data.event_name;
    return this.params?.data.event_name.split('-')[0].trim();
  }

  get away() {
    if (!this.hasData) return '';
    if (this.params?.data.event_type === 'A') return '';
    return this.params?.data.event_name.split('-')[1].trim();
  }

  get leagueName() {
    if (!this.hasData) return '';
    return this.params?.data.competition_name;
  }

  get eventName() {
    if (!this.hasData) return '';
    return this.params?.data.event_name;
  }

  get sportImage() {
    return getSportImage(this.sportCode);
  }
}


import { Component, Mixins, Watch } from 'vue-property-decorator';
import { WithDataTable } from '@/ui/mixins/WithDataTable';
import { TableContainer } from '@/ui/components/TableContainer';
import { SlipApprovalStore } from '@/modules/slipApproval/slipApproval.store';
import ApprovalActions from './ApprovalActions.vue';
import DateTimeCell from '@/ui/components/CellRenderers/DateTimeCell';
import ApprovalTicketsLastFifty from './ApprovalTicketsLastFifty.vue';
import { CommonStore } from '@/modules/common';
import { WithFilters } from '@/ui/mixins/WithFilters';
import UsernameOrPlace from '@/ui/components/CellRenderers/UsernameOrPlace';
import NoteHoverCell from '@/ui/components/CellRenderers/NoteHoverCell.vue';
import CurrencyCell from '@/ui/components/CellRenderers/CurrencyCell';
import { ICellRendererParams } from '@ag-grid-community/core';
import RiskLevelCell from '@/ui/components/CellRenderers/RiskLevelCell.vue';

/* eslint-disable @typescript-eslint/no-var-requires */
const alert = require('../../../../assets/audio/alert.wav');

const TYPE_PARSER = {
  PREMATCH: 'PRE',
  OUTRIGHT: 'OUT',
  MIX: 'MIX',
};

const WALLET_TYPE_PARSER = {
  STANDARD: 'STD',
  PROMO: 'PROMO',
};

@Component({
  name: 'ApprovalTickets',
  components: {
    TableContainer,
    ApprovalActions,
    ApprovalTicketsLastFifty,
    NoteHoverCell,
    RiskLevelCell,
  },
})
export default class ApprovalTickets extends Mixins(WithDataTable, WithFilters) {
  activeSlipId = '';
  warningSound!: HTMLAudioElement;

  get tableProps() {
    return {
      columnDefs: [
        {
          headerName: this.$t('slipApproval.time'),
          field: 'created_at',
          width: 145,
          cellRenderer: DateTimeCell,
          cellRendererParams: {
            date_type: 'timeSeconds',
          },
        },
        {
          headerName: this.$t('slipApproval.usernameBettingPlace'),
          width: 100,
          cellRenderer: UsernameOrPlace,
          lockPosition: true,
        },
        {
          headerName: this.$t('slipApproval.risk'),
          field: 'player_risk',
          width: 95,
          cellRendererFramework: 'RiskLevelCell',
        },
        {
          headerName: this.$t('slipApproval.ticketType'),
          field: 'slip_type',
          width: 60,
          valueFormatter: (row: ICellRendererParams) =>
            TYPE_PARSER[row.value as keyof typeof TYPE_PARSER] || '',
        },
        {
          headerName: this.$t('slipApproval.betAmount'),
          field: 'amount',
          width: 120,
          valueFormatter: CurrencyCell,
        },
        {
          headerName: this.$t('slipApproval.potentialWin'),
          field: 'max_winning_amount',
          width: 110,
          valueFormatter: CurrencyCell,
        },
        {
          headerName: this.$t('slipApproval.minOdd'),
          field: 'min_odds_value',
          width: 85,
          valueFormatter: this.numbersToFixed,
        },
        {
          headerName: this.$t('slipApproval.maxOdd'),
          field: 'max_odds_value',
          width: 85,
          valueFormatter: this.numbersToFixed,
        },
        {
          headerName: this.$t('slipApproval.realOdd'),
          field: 'max_real_odds_value',
          width: 85,
          valueFormatter: this.numbersToFixed,
        },

        {
          headerName: this.$t('slipApproval.walletType'),
          field: 'wallet_type',
          width: 95,
          valueFormatter: (row: ICellRendererParams) => {
            return row.data.slip_env === 'landbase'
              ? ''
              : WALLET_TYPE_PARSER[row.data.wallet_type as keyof typeof WALLET_TYPE_PARSER] ||
                  'STD';
          },
        },
        {
          headerName: this.$t('slipApproval.note'),
          field: 'approval_reason',
          width: 65,
          cellRendererFramework: 'NoteHoverCell',
          cellRendererParams: {
            accessor: 'approval_reason',
          },
        },
      ],
      rowData: this.tableData,
      rowClassRules: {
        'row-active': ({ data }: any) => data.short_uuid === this.activeSlipId,
        pointer: () => true,
      },
    };
  }

  get isInfinite() {
    return false;
  }

  get tableData() {
    return SlipApprovalStore.approvalSlips;
  }

  get selectedSlipBetAmount() {
    return (SlipApprovalStore.selectedSlip as any).amount | 0;
  }

  get totalRecords() {
    return SlipApprovalStore.slipCount;
  }

  get modalVisible() {
    return CommonStore.modalVisible;
  }

  get selectedSlipId() {
    return SlipApprovalStore.selectedSlipId;
  }

  get totalSlips() {
    return SlipApprovalStore.totalSlips;
  }

  async fetchData(_params?: any) {
    await SlipApprovalStore.getSlips();
    this.gridApi?.hideOverlay();
  }

  mounted() {
    this.warningSound = new Audio(alert);
    this.fetchData();
    this.gridApi?.hideOverlay();
  }

  @Watch('selectedSlipId')
  onSelectedChange(val: any) {
    this.activeSlipId = val;
    this.gridApi?.redrawRows();
  }

  numbersToFixed(params: any) {
    if (!params.value) return '/';
    return Number(params.value).toFixed(2);
  }

  onNewSlip() {
    this.warningSound.play();
    this.$notification.warning({
      message: this.$t('common.alert') as string,
      description: this.$t('slipApproval.newSlip') as string,
    });
  }

  onAutoDeny() {
    this.$notification.info({
      message: this.$t('common.info') as string,
      description: this.$t('slipApproval.slipAutoDenied') as string,
    });
  }

  onManualDeny() {
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: this.$t('slipApproval.denySuccess') as string,
    });
  }

  onRowClicked({ data }: any) {
    this.activeSlipId = data.short_uuid;
    // this.gridApi?.redrawRows();
    SlipApprovalStore.setSelected(data.short_uuid);
  }
}


import Vue from 'vue';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from 'vue-property-decorator';

@Component
export default class ApprovalEventCode extends Vue {
  params: ICellRendererParams | undefined;

  get hasData() {
    return !!this.params?.data;
  }

  get event() {
    if (!this.hasData) return {};
    return this.params?.data;
  }

  get eventCode() {
    if (!this.hasData) return '';
    return this.params?.data.event_code;
  }

  parseEventType(eventType: string, eventStage: string) {
    if (eventType === 'P') return 'P';
    if ((eventType === 'D' && eventStage === 'prematch') || eventStage === 'PREMATCH') return 'P';
    if (eventType === 'D' && eventStage === 'live') return 'L';
    if (eventType === 'A') return 'O';
    return '';
  }

  parseColorScheme(type: string, eventStage: string) {
    const eventType = this.parseEventType(type, eventStage);
    const colorClasses: { [key: string]: string } = {
      P: '',
      O: 'blue',
      L: 'red',
    };
    return colorClasses[eventType];
  }
}

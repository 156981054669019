
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ApprovalTickets from './components/ApprovalTickets.vue';
import ApprovalTicketDetails from './components/ApprovalTicketDetails.vue';
import { SlipApprovalStore } from '@/modules/slipApproval/slipApproval.store';
import SlipDetails from '../slip/components/SlipDetails.vue';

@Component({
  name: 'SlipApproval',
  components: {
    SlipDetails,
    ApprovalTickets,
    ApprovalTicketDetails,
  },
})
export default class SlipApprovalPage extends Vue {
  mounted() {
    SlipApprovalStore.init();
  }

  get slipForPreview() {
    return SlipApprovalStore.slipForPreview;
  }

  clearPreview() {
    SlipApprovalStore.setPreviewSlipId('');
  }

  beforeDestroy() {
    SlipApprovalStore.resetStore();
    SlipApprovalStore.disconnect();
  }
}


import isEmpty from 'lodash/isEmpty';
import { Component, Mixins, Prop } from 'vue-property-decorator';

import { WithDataTable } from '@/ui/mixins/WithDataTable';
import { TableContainer } from '@/ui/components/TableContainer';
import DateTimeCell from '@/ui/components/CellRenderers/DateTimeCell';
import { SlipApprovalStore } from '@/modules/slipApproval/slipApproval.store';
import SportLeagueEventCell from '@/ui/components/CellRenderers/SportLeagueEventCell.vue';
import ApprovalOddsManagement from './ApprovalOddsManagement.vue';
import NoteHoverCell from '@/ui/components/CellRenderers/NoteHoverCell.vue';
import OddManagementTableHeader from './OddManagementTableHeader.vue';
import ApprovalEventCode from './ApprovalEventCode.vue';

@Component({
  name: 'ApprovalTicketDetails',
  components: {
    TableContainer,
    SportLeagueEventCell,
    ApprovalOddsManagement,
    NoteHoverCell,
    OddManagementTableHeader,
    ApprovalEventCode,
  },
})
export default class ApprovalTicketDetails extends Mixins(WithDataTable) {
  activeIndex = -1;

  @Prop({ default: false, type: Boolean })
  isPreviewOnly!: boolean;

  get tableProps() {
    return {
      columnDefs: [
        { headerName: this.$t('slipApproval.group'), field: 'group', width: 65 },
        {
          headerName: this.$t('slipApproval.code'),
          cellRendererFramework: 'ApprovalEventCode',
          width: 70,
        },
        {
          headerName: this.$t('slipApproval.date'),
          field: 'event_start_time',
          width: 90,
          cellRenderer: DateTimeCell,
          cellRendererParams: {
            date_type: 'date',
          },
        },
        {
          headerName: this.$t('slipApproval.time'),
          field: 'event_start_time',
          width: 55,
          cellRenderer: DateTimeCell,
          cellRendererParams: {
            date_type: 'time',
          },
        },
        {
          headerName: this.$t('slipApproval.sportLeagueEvent'),
          field: 'sport',
          width: 350,
          cellRendererFramework: 'SportLeagueEventCell',
        },
        {
          headerComponent: 'OddManagementTableHeader',
          field: 'odds',
          width: 550,
          cellRendererFramework: 'ApprovalOddsManagement',
          autoHeight: true,
          cellClass: 'oddManagementCell',
          cellRendererParams: {
            isPreviewOnly: this.isPreviewOnly,
          },
        },
        {
          headerName: this.$t('slipApproval.reason'),
          field: 'denial_reason',
          width: 65,
          cellRendererFramework: 'NoteHoverCell',
          cellRendererParams: {
            accessor: 'denial_reason',
            isApproval: true,
          },
        },
      ],
      rowData: this.tableData,
    };
  }

  get tableData() {
    if (!isEmpty(SlipApprovalStore.selectedSlip)) {
      return SlipApprovalStore.activeSlipEvents;
    }
    return [];
  }

  get totalRecords() {
    return this.tableData.length;
  }

  get isInfinite() {
    return false;
  }

  mounted() {
    setTimeout(() => this.gridApi?.hideOverlay());
    setTimeout(() => this.gridApi?.showNoRowsOverlay());
  }
}

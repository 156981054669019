
import Vue from 'vue';
import Component from 'vue-class-component';
import { ICellRendererParams } from '@ag-grid-community/core';

const RISK_COLORS = {
  0: '',
  1: 'primary-color',
  2: 'yellow-color',
  3: 'warning-color',
  4: 'bright-red-color',
  5: 'purple-color',
};

@Component
export default class RiskLevelCell extends Vue {
  params: ICellRendererParams | undefined;

  get riskLevel() {
    if (!this.params?.data) return '';
    if (!this.params.value) return '';
    return this.params.value;
  }

  get riskLevelColor() {
    //@ts-ignore-next-line
    return RISK_COLORS[this.riskLevel];
  }
}


import Vue from 'vue';
import to from 'await-to-js';
import { Component, Prop, Watch } from 'vue-property-decorator';
import Inputmask from 'inputmask/dist/inputmask';

import { SlipApprovalStore } from '@/modules/slipApproval/slipApproval.store';
import { CommonStore } from '@/modules/common';

@Component({ name: 'ApprovalActions' })
export default class ApprovalTickets extends Vue {
  tempBetAmount = 0;
  denialReason = '';
  showDenyPopConfirm = false;
  showApprovePopConfirm = false;

  @Prop({ default: 0 })
  betAmount!: string;

  get inputValue() {
    return this.tempBetAmount || this.betAmount;
  }

  get selectedSlipId() {
    return SlipApprovalStore.selectedSlipId;
  }

  get canEdit() {
    return SlipApprovalStore.canEditSlip;
  }

  get approvalPayload() {
    return SlipApprovalStore.approvalPayload;
  }

  get toggleModal() {
    return (modal: any) => CommonStore.toggleModal(modal);
  }

  get isLastFiftyDisabled() {
    return CommonStore.modalVisible === 'lastFifty';
  }

  get isChangingState() {
    return SlipApprovalStore.isChangingState;
  }

  get isSlipEdited() {
    return SlipApprovalStore.isSlipEdited;
  }

  get isBetAmountInvalid() {
    if (
      typeof this.tempBetAmount === 'string' ||
      (this.tempBetAmount < 20 && this.tempBetAmount !== 0)
    ) {
      return true;
    }
    return false;
  }

  @Watch('betAmount')
  betAmountHandler() {
    const inputElement = this.$el.querySelector('#limit');

    Inputmask('currency', {
      max: this.betAmount,
      min: 20,
    }).mask(inputElement);
  }

  @Watch('selectedSlipId')
  onSlipChange() {
    this.tempBetAmount = 0;
  }

  onError() {
    this.$notification.error({
      message: this.$t('common.error') as string,
      description: this.$t('common.somethingWentWrong') as string,
    });
  }

  onSuccess(description: string) {
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: description,
    });
  }

  checkIfBetAmountIsEdited() {
    if (this.tempBetAmount === 0) return false;
    if (this.tempBetAmount !== Number(this.betAmount)) return true;
    return false;
  }

  async onApprove() {
    SlipApprovalStore.setApprovalPayloadBetAmount(Number(this.inputValue));
    if (this.checkIfBetAmountIsEdited()) {
      SlipApprovalStore.setIsSlipEdited(true);
    }
    this.showApprovePopConfirm = false;
    const [err, _res] = await to<any>(SlipApprovalStore.approveSlip(this.selectedSlipId));
    if (err) return this.onError();
    const successMessage = this.isSlipEdited
      ? this.$t('slipApproval.editSuccess')
      : this.$t('slipApproval.approveSuccess');
    this.onSuccess(successMessage as string);
    SlipApprovalStore.setIsSlipEdited(false);
  }

  async onDeny() {
    this.showDenyPopConfirm = false;
    const [err, _res] = await to<any>(
      SlipApprovalStore.denySlip({ slipId: this.selectedSlipId, denialMessage: this.denialReason })
    );
    this.denialReason = '';
    if (err) return this.onError();
    SlipApprovalStore.setIsSlipEdited(false);
  }

  onBetAmountChange(newValue: any) {
    this.tempBetAmount = newValue;
  }

  onDenialReasonChange(inputEvent: any) {
    this.denialReason = inputEvent.target.value;
  }

  onCancelApprove() {
    this.showApprovePopConfirm = false;
  }

  onCancelDeny() {
    this.showDenyPopConfirm = false;
  }
}


import Vue from 'vue';
import Component from 'vue-class-component';
import { ICellRendererParams } from '@ag-grid-community/core';

interface NoteHoverCellParams extends ICellRendererParams {
  accessor: string;
  isApproval: boolean;
}

@Component
export default class NoteHoverCell extends Vue {
  params: NoteHoverCellParams | undefined;

  get hasData() {
    return !!this.params?.data;
  }

  get note() {
    if (!this.hasData) return '';
    return this.params?.data[this.params.accessor] || this.$t('slipApproval.noOperatorNote');
  }

  get isPopupOverflown() {
    return this.note.length > 200;
  }

  get hasNote() {
    return !!this.params?.data[this.params?.accessor];
  }

  get iconHidden() {
    return this.params?.isApproval && !this.params?.data[this.params.accessor];
  }

  get overflownOverlayContainer() {
    if (this.isPopupOverflown) return 'overflownOverlayContainer';
    return '';
  }
}


import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Editable } from '@/ui/components/Editable';
import { SlipApprovalStore } from '@/modules/slipApproval/slipApproval.store';
import isEmpty from 'lodash/isEmpty';
import { slipApprovalService } from '@/modules/slipApproval/slipApproval.service';

@Component({ components: { Editable } })
export default class OddLimitAndValueCell extends Vue {
  @Prop()
  editType!: 'odd' | 'limit' | 'limit2';

  @Prop()
  limitMin!: number;

  @Prop()
  limitMax!: number;

  @Prop()
  odd!: any;

  payloadValue = this.odd[this.accesor];
  isManualChanged = false;

  get accesor() {
    return `${this.editType}_value`;
  }

  get value() {
    return this.payloadValue || this.odd[this.accesor];
  }

  get editLimitMin() {
    return this.limitMin;
  }

  get editLimitMax() {
    return this.limitMax;
  }

  errorMessage(description = this.$t('genericError.description')) {
    this.$notification.error({
      message: this.$t('common.error') as string,
      description: description as string,
    });
  }

  get isContentEditable() {
    if (this.editType === 'odd') return true;
    if (this.value === '0.00' || this.value === 0) return false;
    return true;
  }

  onOddChange(number: number) {
    const odd = {
      odd_id: this.odd.int_key,
      odd_value: number,
    };
    SlipApprovalStore.setApprovalPayloadOddValue(odd);
    this.payloadValue = number;
    SlipApprovalStore.setIsSlipEdited(true);
  }

  onLimitChange(number: number) {
    if (Number.isInteger(Number(number)) || Number(number) % 0.5 !== 0) return;
    if (!slipApprovalService.isLimitValid(number)) {
      return this.errorMessage(this.$t('slipApproval.invalidLimit'));
    }

    const oddLimit: any = {};
    if (this.odd.limit_value && this.editType === 'limit') {
      oddLimit.odd_id = this.odd.int_key;
      oddLimit.limit_value = number;
    }

    const oddLimit2: any = {};
    if (this.odd.limit2_value && this.editType === 'limit2') {
      oddLimit2.odd_id = this.odd.int_key;
      oddLimit2.limit2_value = number;
    }

    if (isEmpty(SlipApprovalStore.approvalPayload.limit) && !isEmpty(oddLimit2)) {
      SlipApprovalStore.setApprovalPayloadOddLimitValue({
        odd_id: this.odd.int_key,
        limit_value: this.odd.limit_value,
      });
    }

    SlipApprovalStore.setApprovalPayloadOddLimitValue(isEmpty(oddLimit) ? oddLimit2 : oddLimit);
    this.payloadValue = number;
    SlipApprovalStore.setIsSlipEdited(true);
  }

  handleChange(number: number) {
    const onChange = this.editType === 'odd' ? this.onOddChange : this.onLimitChange;
    const validNumber = number === 1 || !number ? this.odd[this.accesor] : number; // reset odd if less than 1, input number limit makes it allways
    onChange(validNumber);
  }

  confrimChange() {
    this.isManualChanged =
      this.odd[this.accesor] !==
      //@ts-ignore
      Number(SlipApprovalStore.approvalPayload[this.editType][this.odd.int_key]);
  }
}

import { SlipEnv } from '../slip/slip.types';
import {
  FeedSlip,
  FeedSlipGroup,
  FeedTicketEvent,
  FeedTicketOdd,
  DenialReason,
} from './slipApproval.types';

export class ApprovalSlip {
  created_at: string;
  amount: number;
  id: string;
  approval_reason: string | null;
  user: number;
  username: string;
  slip_groups: any;
  slip_env: SlipEnv;
  slip_type: string;
  number_of_combinations: number;
  automated_status: string | null;
  manual_status: string | null;
  max_odds_value: number;
  min_odds_value: number;
  actual_odds_value: number | null;
  transaction_id: string;
  number_of_matches: number;
  approved_by: string | null;
  betting_place_id: string | null;
  short_uuid: string;
  max_winning_amount: number;
  min_winning_amount: number;
  actual_winning_amount: number | null;
  is_system: boolean;
  denial_reason: { errors: DenialReason[] };
  player_risk: number;

  constructor(slip: FeedSlip) {
    this.created_at = slip.c_a;
    this.amount = slip.a;
    this.approval_reason = slip.a_r;
    this.user = slip.u;
    this.username = slip.u_n;
    this.slip_env = slip.s_e;
    this.slip_type = slip.s_t;
    this.id = slip.i;
    this.denial_reason = slip.d_r;
    (this.slip_groups = slip.s_g.map((s_g: FeedSlipGroup) => {
      return {
        system: s_g.s,
        events: s_g.e.map((e: FeedTicketEvent) => {
          return {
            event_id: e.e_i_k,
            int_key: e.e_i_k,
            odds: e.o.map((o: FeedTicketOdd) => {
              return {
                odd_id: o.o_i,
                odd_value: Number(o.o_v),
                limit_value: Number(o.l_v),
                limit2_value: Number(o.l2_v),
                automated_status: o.a_s,
                manual_status: o.m_s,
                outcome_name: o.o_n,
                outcome_print_code: o.o_p_c,
                real_odd_value: o.r_o_v,
                market_name: o.m_n,
                int_key: o.o_i_k,
              };
            }),
            automated_status: e.a_s,
            manual_status: e.m_s,
            event_name: e.e_n,
            event_type: e.e_t,
            event_stage: e.e_s,
            event_start_time: e.e_s_t,
            event_code: e.e_c,
            event_note: e.e_n_t,
            sport_id: e.s,
            competition_id: e.c,
            competition_name: e.c_n,
            competition_short_name: e.c_s_n,
            location_id: e.loc,
            location_name: e.l_n,
            sport_name: e.s_n,
            sport_code: e.e_s_c,
          };
        }),
        number_of_combinations: s_g.n_c,
        automated_status: s_g.a_s,
        manual_status: s_g.m_s,
      };
    })),
      (this.manual_status = null);
    this.automated_status = null;
    this.number_of_combinations = slip.n_c;
    this.max_odds_value = slip.max_o_v;
    this.min_odds_value = slip.min_o_v;
    this.actual_odds_value = slip.a_o_w;
    this.transaction_id = slip.t_i;
    this.number_of_matches = slip.n_m;
    this.approved_by = slip.a_by;
    this.betting_place_id = slip.b_p_id;
    this.short_uuid = slip.s_u;
    this.max_winning_amount = slip.max_w_a;
    this.min_winning_amount = slip.min_w_a;
    this.actual_winning_amount = slip.act_w_a;
    this.is_system = slip.i_s;
    this.player_risk = slip.p_r;
  }
}

import { wsHeartBeat } from '@/lib/wsHeartBeat';
import { ApprovalHandlers } from './slipApproval.types';

class FeedApi {
  private socket!: WebSocket;
  private handlers!: ApprovalHandlers;

  connect(handlers?: ApprovalHandlers) {
    const url = process.env.VUE_APP_APPROVAL_WS_FEED;
    if (handlers) {
      this.handlers = handlers;
    }
    this.socket = new WebSocket(url);
    this.heartBeat();

    this.socket.addEventListener('open', function() {
      const event = new Event('feed_open');
      document.dispatchEvent(event);
      console.debug('Connection with approval feed established');
    });

    this.socket.addEventListener('close', function() {
      const event = new Event('feed_close');
      document.dispatchEvent(event);
      console.debug('Connection with approval feed closed');
    });

    this.socket.addEventListener('message', ({ data }: any) => {
      try {
        const { type, message } = JSON.parse(data) as {
          type: keyof ApprovalHandlers;
          message: any;
        };
        // @ts-ignore
        this.handlers[type] && this.handlers[type](message);
      } catch (_) {
        // do nothing
      }
    });
  }

  heartBeat() {
    wsHeartBeat(this.socket, () => this.connect());
  }

  disconnect() {
    this.socket.close();
  }
}

export default FeedApi;

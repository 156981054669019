import moment from 'moment';

import ApprovalApi from './slipApproval.api';
import {
  ApprovalHandlers,
  ApproveSlipPayload,
  Slip,
  SlipGroup,
  DenialReason,
} from './slipApproval.types';
import { slipApprovalRepo } from './slipApproval.repo';

class SlipApprovalService {
  api = new ApprovalApi();

  init(messageHandlers: ApprovalHandlers) {
    this.api.connect(messageHandlers);
  }

  disconect() {
    this.api.disconnect();
  }

  getSlips(): Promise<any> {
    return slipApprovalRepo.getApprovalSlips();
  }

  getAllApprovals(): Promise<any> {
    return slipApprovalRepo.getAllApprovals();
  }

  approveSlip(slipId: string, payload: ApproveSlipPayload): Promise<any> {
    return slipApprovalRepo.approveSlip(slipId, payload);
  }

  denySlip(slipId: string, denialMessage?: string): Promise<any> {
    return slipApprovalRepo.denySlip(slipId, denialMessage ? denialMessage : undefined);
  }

  getMillionSlips() {
    return slipApprovalRepo.getMillionSlips();
  }

  approveMillionSlip(slipId: string) {
    return slipApprovalRepo.approveMillionSlip(slipId);
  }

  findSlip(slipId: string, slips: any) {
    return slips.find((slip: any) => slip.short_uuid === slipId);
  }

  getAllOdds(slip: Slip) {
    return slip.slip_groups.map(slipGroup => {
      const odds: {
        [key: string]: number;
      } = {};
      slipGroup.events.forEach(event => {
        event.odds.forEach(odd => {
          odds[odd.int_key] = odd.odd_value;
        });
      });
      return odds;
    });
  }

  mapGroupToSlipEvents(slip: Slip) {
    const events: any = [];
    slip.slip_groups.forEach((slipGroup: SlipGroup, index: number) => {
      slipGroup.events.forEach((event: any) => {
        events.push({
          ...event,
          group: `G-${index + 1}`,
          denial_reason: slip.denial_reason.errors?.find(
            (error: DenialReason) => Number(error.e_i_k) === Number(event.int_key)
          )?.error,
        });
      });
    });
    const sortedEventsByTime = events.sort((a: any, b: any) => {
      // format('x') converts date to miliseconds
      return (
        // @ts-ignore
        (moment(a.event_start_time).format('x') as number) - moment(b.event_start_time).format('x')
      );
    });
    return sortedEventsByTime;
  }

  isLimitValid(limit: number) {
    if (limit % 1 === 0 || limit % 1 === 0.5 || limit % 1 === -0.5 || limit % 1 === (-0 as number))
      return true;
    return false;
  }
}

export const slipApprovalService = new SlipApprovalService();

import { getModule, Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import store from '@/store';
import { to } from 'await-to-js';
import { ApproveSlipPayload, FeedSlip, Slip } from './slipApproval.types';
import { slipApprovalService } from './slipApproval.service';
import { ApprovalSlip } from './slipApproval.entity';
import isEmpty from 'lodash/isEmpty';

const initialState = () => ({
  totalSlips: 0,
  approvalSlips: [],
  selectedSlipId: '',
  selectedSlip: {},
  lastFiftySlips: [],
  isChangingState: false,
  previewSlipId: '',
  isSlipEdited: false,
  slipIdToAvoidAutoDeny: '',
  approvalPayload: {
    amount: 0,
    odd: {},
    limit: {},
    limit2: {},
  },
});

@Module({ dynamic: true, store, name: 'slipApproval', namespaced: true })
class SlipApproval extends VuexModule {
  totalSlips = initialState().totalSlips;
  approvalSlips: Slip[] = initialState().approvalSlips;
  selectedSlipId = initialState().selectedSlipId;
  selectedSlip: Slip | {} = initialState().selectedSlip;
  approvalPayload: ApproveSlipPayload = initialState().approvalPayload;
  lastFiftySlips: Slip[] = initialState().lastFiftySlips;
  isChangingState = initialState().isChangingState;
  previewSlipId = initialState().previewSlipId;
  slipIdToAvoidAutoDeny = initialState().slipIdToAvoidAutoDeny;
  isSlipEdited = initialState().isSlipEdited;

  get slipCount() {
    return this.approvalSlips.length;
  }

  get lastFiftyCount() {
    return this.lastFiftySlips.length;
  }

  get activeSlipEvents() {
    if (isEmpty(this.selectedSlip)) return null;
    return slipApprovalService.mapGroupToSlipEvents(this.selectedSlip as Slip);
  }

  get slipForPreview() {
    return this.lastFiftySlips.find(s => s.id === this.previewSlipId);
  }

  get canEditSlip() {
    if (isEmpty(this.selectedSlip)) return false;
    return (this.selectedSlip as Slip).slip_type !== 'MIX';
  }

  @Mutation
  setIsSlipEdited(isEdited: boolean) {
    this.isSlipEdited = isEdited;
  }

  @Mutation
  setPreviewSlipId(slipId: string) {
    this.previewSlipId = slipId;
  }

  @Mutation
  setIsChangingState(state: boolean) {
    this.isChangingState = state;
  }

  @Mutation
  setSlips(slips: Slip[]) {
    this.approvalSlips = slips;
  }

  @Mutation
  setLastFifty(slips: Slip[]) {
    this.lastFiftySlips = slips;
  }

  @Mutation
  setSlipIdToAvoidAutoDeny(slipId: string) {
    this.slipIdToAvoidAutoDeny = slipId;
  }

  @Mutation
  setSelectedSlip(slip: Slip) {
    this.selectedSlip = slip;
    this.selectedSlipId = slip.short_uuid;
    this.approvalPayload.amount = slip.amount;
    slipApprovalService.getAllOdds(slip).forEach(odd => {
      Object.entries(odd).forEach(([key, value]: any) => {
        this.approvalPayload.odd[key] = Number(value).toFixed(2);
      });
    });
  }

  @Mutation
  clearSelectedSlip() {
    this.selectedSlipId = '';
    this.selectedSlip = {};
    this.approvalPayload = initialState().approvalPayload;
  }

  @Mutation
  setApprovalPayloadBetAmount(betAmount: number) {
    this.approvalPayload.amount = betAmount;
  }

  @Mutation
  setApprovalPayloadOddValue(odd: any) {
    this.approvalPayload.odd[odd.odd_id] = Number(odd.odd_value).toFixed(2);
  }

  @Mutation
  setApprovalPayloadOddLimitValue(odd: any) {
    if (isEmpty(odd)) return;
    const limitkey = [Object.keys(odd)[1]][0].split('_')[0];
    const limitValue = Number(odd[Object.keys(odd)[1]]).toFixed(2);
    //@ts-ignore
    this.approvalPayload[limitkey][odd.odd_id] = limitValue;
  }

  @Mutation
  setTotalSlips(slipCount: number) {
    this.totalSlips = slipCount;
  }

  @Mutation
  addSlip(slip: any) {
    this.approvalSlips = [slip, ...this.approvalSlips];
    this.totalSlips = this.totalSlips + 1;
  }

  @Mutation
  removeSlip(slipId: string) {
    this.approvalSlips = this.approvalSlips.filter(
      approvalSlip => approvalSlip.short_uuid !== slipId
    );
    if (this.totalSlips === 0) return;
    this.totalSlips = this.totalSlips - 1;
  }

  @Action
  onApprovalMessage(message: FeedSlip) {
    const mappedMsg = new ApprovalSlip(message);
    this.addSlip(mappedMsg);
  }

  @Action
  onDenialMessage(message: any) {
    if (message.short_uuid === this.slipIdToAvoidAutoDeny) return;
    this.removeSlip(message.short_uuid);
    if (this.selectedSlipId === message.short_uuid) {
      this.clearSelectedSlip();
    }
    this.setSlipIdToAvoidAutoDeny('');
  }

  @Action
  init() {
    const handlers = {
      slip_for_approval: this.onApprovalMessage,
      slip_denied: this.onDenialMessage,
    };
    slipApprovalService.init(handlers);
  }

  @Action
  disconnect() {
    slipApprovalService.disconect();
  }

  @Mutation
  resetStore() {
    const initial = initialState() as any;
    Object.keys(initial).forEach((key: any) => {
      this[key as keyof this] = initial[key];
    });
  }

  @Action
  async getSlips() {
    const [err, res] = await to<any>(slipApprovalService.getSlips());
    if (err) return Promise.reject(err);
    this.setSlips(res.results);
    this.setTotalSlips(res.count < 0 ? 0 : res.count);
    Promise.resolve();
  }

  @Action
  async getLastFifty() {
    const [err, res] = await to<any>(slipApprovalService.getAllApprovals());
    if (err) return Promise.reject(err);
    this.setLastFifty(res.results);
    Promise.resolve();
  }

  @Action
  setSelected(slipId: string) {
    this.clearSelectedSlip();
    const slip = slipApprovalService.findSlip(slipId, this.approvalSlips);
    this.setSelectedSlip(slip);
    this.setIsSlipEdited(false);
  }

  @Action
  selectLastSlip() {
    this.clearSelectedSlip();
    const slip = this.approvalSlips[this.approvalSlips.length - 1];
    this.setSelectedSlip(slip);
    this.setIsSlipEdited(false);
  }

  @Action
  async approveSlip(slipId: string) {
    this.setIsChangingState(true);
    const [err] = await to<any>(slipApprovalService.approveSlip(slipId, this.approvalPayload));
    this.setIsChangingState(false);
    if (err) return Promise.reject(err);
    this.removeSlip(slipId);
    this.clearSelectedSlip();
    if (this.approvalSlips.length === 0) return;
    this.selectLastSlip();
    return Promise.resolve();
  }

  @Action
  async denySlip({ slipId, denialMessage }: { slipId: string; denialMessage?: string }) {
    this.setSlipIdToAvoidAutoDeny(slipId);
    this.setIsChangingState(true);
    const [err] = await to<any>(slipApprovalService.denySlip(slipId, denialMessage));
    this.setIsChangingState(false);
    if (err) return Promise.reject(err);
    this.removeSlip(slipId);
    this.clearSelectedSlip();
    if (this.approvalSlips.length === 0) return;
    this.selectLastSlip();
    return Promise.resolve();
  }

  @Action
  async getMillionSlips() {
    const [err, res] = await to(slipApprovalService.getMillionSlips());
    if (err) return Promise.reject(err);
    this.setSlips((res as any).results);
    this.setTotalSlips((res as any).count);
  }

  @Action
  async approveMillionSlip(slipId: string) {
    const [err] = await to(slipApprovalService.approveMillionSlip(slipId));
    if (err) return Promise.reject(err);
    this.removeSlip(slipId);
    this.clearSelectedSlip();
  }
}

export const SlipApprovalStore = getModule(SlipApproval);

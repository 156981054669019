import { api } from '@/lib/api';
import { ApproveSlipPayload } from './slipApproval.types';

const getApprovalSlips = (): Promise<any> => {
  return api.get('slip/slip/approving/', {
    cache: {
      maxAge: 0,
    },
  });
};

const approveSlip = (slipId: string, payload: ApproveSlipPayload): Promise<any> => {
  return api.put(`slip/slip/${slipId}/manual-approving/`, payload);
};

const denySlip = (slipId: string, denialMessage?: string): Promise<any> => {
  return api.put(`slip/slip/${slipId}/manual-denied/`, { manual_denied_reason: denialMessage });
};

const getAllApprovals = (): Promise<any> => api.get('slip/slip/for-approving/?limit=50');

const getMillionSlips = () =>
  api.get('slip/slip/million-tickets/', {
    cache: {
      maxAge: 0,
    },
  });

const approveMillionSlip = (slipId: string) => api.post(`slip/slip/${slipId}/approve-payout/`);

export const slipApprovalRepo = {
  getApprovalSlips,
  getAllApprovals,
  approveSlip,
  denySlip,
  getMillionSlips,
  approveMillionSlip,
};


import { Component, Mixins } from 'vue-property-decorator';
import { WithDataTable } from '@/ui/mixins/WithDataTable';
import { TableContainer } from '@/ui/components/TableContainer';
import { SlipApprovalStore } from '@/modules/slipApproval/slipApproval.store';
import ApprovalActions from './ApprovalActions.vue';
import DateTimeCell from '@/ui/components/CellRenderers/DateTimeCell';
import { CommonStore } from '@/modules/common';
import UsernameOrPlace from '@/ui/components/CellRenderers/UsernameOrPlace';
import { SlipStatus } from '@/modules/slip/slip.types';
import CurrencyCell from '@/ui/components/CellRenderers/CurrencyCell';

@Component({ name: 'ApprovalTicketsLastFifty', components: { TableContainer, ApprovalActions } })
export default class ApprovalTicketsLastFifty extends Mixins(WithDataTable) {
  activeIndex = -1;
  get tableProps() {
    return {
      columnDefs: [
        { headerName: this.$t('slipApproval.slipId'), field: 'short_uuid', width: 120 },
        { headerName: '', field: 'denied_by', width: 2, cellStyle: { fontWeight: 'bold' } },
        {
          headerName: this.$t('slipApproval.usernameBettingPlace'),
          field: 'user',
          width: 90,
          cellRenderer: UsernameOrPlace,
        },
        {
          headerName: this.$t('slipApproval.date'),
          field: 'created_at',
          width: 130,
          cellRenderer: DateTimeCell,
          cellRendererParams: {
            date_type: 'date',
          },
        },
        {
          headerName: this.$t('slipApproval.time'),
          field: 'created_at',
          width: 90,
          cellRenderer: DateTimeCell,
          cellRendererParams: {
            date_type: 'time',
          },
        },
        {
          headerName: this.$t('slipApproval.betAmount'),
          field: 'amount',
          width: 120,
          valueFormatter: CurrencyCell,
        },
        {
          headerName: this.$t('slipApproval.potentialWin'),
          field: 'max_winning_amount',
          width: 140,
          valueFormatter: CurrencyCell,
        },
        { headerName: this.$t('slipApproval.minOdd'), field: 'min_odds_value', width: 100 },
        { headerName: this.$t('slipApproval.maxOdd'), field: 'max_odds_value', width: 100 },
      ],
      rowData: this.tableData,
      rowClassRules: {
        'row-active': ({ rowIndex }: any) => rowIndex === this.activeIndex,
        'ticket-denied': ({ data }: any) => {
          return data.status === SlipStatus.DENIED;
        },
        'ticket-canceled': ({ data }: any) => {
          return data.status === SlipStatus.CANCELED;
        },
        'ticket-approved': ({ data }: any) => {
          return data.status !== SlipStatus.DENIED && data.status !== SlipStatus.APPROVING;
        },
      },
    };
  }

  get toggleModal() {
    return (modal: any) => CommonStore.toggleModal(modal);
  }

  get isInfinite() {
    return false;
  }

  get tableData() {
    return SlipApprovalStore.lastFiftySlips;
  }

  get totalRecords() {
    return SlipApprovalStore.lastFiftyCount;
  }

  async fetchData(_params?: any) {
    await SlipApprovalStore.getLastFifty();
    this.gridApi?.hideOverlay();
  }

  onRowClicked({ data }: any) {
    SlipApprovalStore.setPreviewSlipId(data.id);
  }

  mounted() {
    this.fetchData();
  }
}


import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ICellRendererParams } from '@ag-grid-community/core';

import OddLimitAndValueCell from '@/ui/components/CellRenderers/OddLimitAndValueCell.vue';

interface ApprovalOddsManagementProps extends ICellRendererParams {
  isPreviewOnly: boolean;
}

@Component({
  name: 'ApprovalOddsManagement',
  components: { OddLimitAndValueCell },
})
export default class ApprovalOddsManagement extends Vue {
  params: ApprovalOddsManagementProps | undefined;

  get allOdds() {
    return this.params?.value;
  }

  get isPreviewOnly() {
    return this.params?.isPreviewOnly || this.params?.data.event_stage === 'live';
  }

  fieldToUpdate(odd: any) {
    if (odd.limit2_value) {
      return ['limit2', 'limit'];
    }
    if (odd.limit_value && !odd.limit2_value) {
      if (odd.outcome_print_code.toLowerCase().includes('h')) {
        return ['limit', 'limit2'];
      } else {
        return ['limit2', 'limit'];
      }
    }
    return '';
  }
}
